import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import GIF from '../images/404.webp'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div className="container container-max-md">
        <article className="entry">
          <h1>404: Not Found</h1>
          <p>You opened a location that doesn't exist. <Link to="/">Return to homepage.</Link></p>
          <img
            className="gif-404 mw-100 img-fluid d-block mx-auto mt-5"
            src={GIF}
            width={480}
            height={270}
            alt="404 Not found"
          />
        </article>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
